<template>
    <div>
        <v-app
            id="inspire"
            class="ventesapp"
        >
            <v-layout
                class="syncContainer"
                column
                fill-height
            >
                <header>
                    <div class="gradient" />
                    <v-app-bar
                        color="black"
                        dark
                    >
                        <v-btn
                            icon
                            to="/"
                        >
                            <v-icon>arrow_back</v-icon>
                        </v-btn>
                        <h2>
                            {{ $t("sync.title") }}
                        </h2>
                    </v-app-bar>
                </header>
                <v-container
                >
                    <v-flex
                        xs12
                        sm6
                        offset-sm3
                    >
                        <v-list-item>
                            <v-btn
                                v-if="!resync"
                                disabled
                                block
                            >
                                {{ $t("sync.sync") }}
                            </v-btn>
                            <v-btn
                                v-else
                                block
                                @click="reSyncronisation"
                            >
                                {{ $t("sync.resync") }}
                            </v-btn>
                        </v-list-item>
                        <v-list-item>
                            {{ $t("sync.clients") }}
                            {{ $t(clientTerm) }}
                            <v-progress-circular
                                v-if="clientTerm === 'sync.loading'"
                                indeterminate
                                size="24"
                                color="primary"
                            />
                        </v-list-item>
                        <v-list-item
                            v-if="artTerm !== ''"
                        >
                            {{ $t("sync.articles") }}
                            {{ $t(artTerm) }}
                            <v-progress-circular
                                v-if="artTerm === 'sync.loading'"
                                indeterminate
                                size="24"
                                color="primary"
                            />
                        </v-list-item>
                        <v-list-item
                            v-if="priceTerm !== ''"
                        >
                            {{ $t("sync.prices") }}
                            {{ $t(priceTerm) }}
                            <v-progress-circular
                                v-if="priceTerm === 'sync.loading'"
                                indeterminate
                                size="24"
                                color="primary"
                            />
                        </v-list-item>
                        <v-list-item
                            v-if="packageTerm !== ''"
                        >
                            {{ $t("sync.packages") }}
                            {{ $t(packageTerm) }}
                            <v-progress-circular
                                v-if="packageTerm === 'sync.loading'"
                                indeterminate
                                size="24"
                                color="primary"
                            />
                        </v-list-item>
                        <v-list-item
                            v-if="saleTerm !== ''"
                        >
                            {{ $t("sync.sales") }}
                            {{ $t(saleTerm) }}
                            <v-progress-circular
                                v-if="saleTerm === 'sync.loading'"
                                indeterminate
                                size="24"
                                color="primary"
                            />
                        </v-list-item>
                        <v-list-item
                            v-if="remoteSettingsTerm !== ''"
                        >
                            {{ $t("sync.remoteSettings") }}
                            {{ $t(remoteSettingsTerm) }}
                            <v-progress-circular
                                v-if="remoteSettingsTerm === 'sync.loading'"
                                indeterminate
                                size="24"
                                color="primary"
                            />
                        </v-list-item>
                        <v-list-item>
                            <v-btn
                                to="/"
                                block
                            >
                                {{ $t("sync.return") }}
                            </v-btn>
                        </v-list-item>
                    </v-flex>
                </v-container>
                <v-snackbar
                    v-model="errorSnackbar"
                    top
                    color="error"
                >
                    {{ errorMessage }}
                    <v-btn
                        dark
                        text
                        @click="errorSnackbar = false"
                    >
                        close
                    </v-btn>
                </v-snackbar>
            </v-layout>
        </v-app>
    </div>
</template>
<script>
import axios from 'axios'
import localforage from 'localforage'

export default {
    name: 'Sync',
    data () {
        return {
            clientTerm: '',
            artTerm: '',
            priceTerm: '',
            packageTerm: '',
            saleTerm: '',
            remoteSettingsTerm: '',
            errorMessage: '',
            errorSnackbar: false,
            resync: false,
            settings: {}
        }
    },
    created () {
        this.loadFromLocalStorage()
    },
    mounted () {
        // axios settings
        axios.defaults.baseURL = this.settings.apiUrl
        axios.defaults.headers.common['Wisol-Api-Device-Key'] = this.settings.apiDeviceKey
        axios.defaults.headers.common['Wisol-Api-App-Key'] = 'f6345e3e-4956-3fb4-91ca-404f62d115ec'
        this.getClients()
    },
    methods: {
        async getClients () {
            // axios request to get clients
            this.clientTerm = 'sync.loading'
            try {
                await axios({
                    url: axios.defaults.baseURL + '/module/orderproposal/api/clients',
                    auth: {
                        username: this.settings.apiUsername,
                        password: this.settings.apiPassword
                    },
                    method: 'get',
                    responseType: 'json'
                })
                    .then((response) => {
                        const clients = response.data
                        this.clientTerm = 'sync.finish'
                        return localforage.setItem('clients', clients)
                            .then(() => this.getArticles())
                    })
            } catch (error) {
                // eslint-disable-next-line
                console.log(error)
                this.failedSyncronisation()
                this.clientTerm = 'sync.error'
                if (error.response) {
                    this.errorSnackbar = true
                    this.errorMessage = error.response.data.errors[0].message
                }
            }
        },
        async getArticles () {
            // axios request to get articles
            this.artTerm = 'sync.loading'
            try {
                await axios({
                    url: axios.defaults.baseURL + '/module/orderproposal/api/articles',
                    auth: {
                        username: this.settings.apiUsername,
                        password: this.settings.apiPassword
                    },
                    method: 'get',
                    responseType: 'json'
                })
                    .then((response) => {
                        const articles = response.data
                        this.artTerm = 'sync.finish'
                        return localforage.setItem('articles', articles)
                            .then(() => this.getPrices())
                    })
            } catch (error) {
                // eslint-disable-next-line
                console.log(error)
                this.failedSyncronisation()
                this.artTerm = 'sync.error'
                if (error.response) {
                    this.errorSnackbar = true
                    this.errorMessage = error.response.data.errors[0].message
                }
            }
        },
        async getPrices () {
            // axios request to get prices
            this.priceTerm = 'sync.loading'
            try {
                await axios({
                    url: axios.defaults.baseURL + '/module/orderproposal/api/prices',
                    auth: {
                        username: this.settings.apiUsername,
                        password: this.settings.apiPassword
                    },
                    method: 'get',
                    responseType: 'json'
                })
                    .then((response) => {
                        const prices = response.data
                        this.priceTerm = 'sync.finish'
                        return localforage.setItem('prices', prices)
                            .then(() => this.getPackages())
                    })
            } catch (error) {
                // eslint-disable-next-line
                console.log(error)
                this.failedSyncronisation()
                this.priceTerm = 'sync.error'
                if (error.response) {
                    this.errorSnackbar = true
                    this.errorMessage = error.response.data.errors[0].message
                }
            }
        },
        async getPackages () {
            // axios request to get packages
            this.packageTerm = 'sync.loading'
            try {
                await axios({
                    url: axios.defaults.baseURL + '/module/orderproposal/api/packs',
                    auth: {
                        username: this.settings.apiUsername,
                        password: this.settings.apiPassword
                    },
                    method: 'get',
                    responseType: 'json'
                })
                    .then((response) => {
                        const packages = response.data
                        this.packageTerm = 'sync.finish'
                        return localforage.setItem('packages', packages)
                            .then(() => this.getSale())
                    })
            } catch (error) {
                // eslint-disable-next-line
                console.log(error)
                this.failedSyncronisation()
                this.packageTerm = 'sync.error'
                if (error.response) {
                    this.errorSnackbar = true
                    this.errorMessage = error.response.data.errors[0].message
                }
            }
        },
        async getSale () {
            // axios request to get sales
            this.saleTerm = 'sync.loading'
            try {
                await axios({
                    url: axios.defaults.baseURL + '/module/orderproposal/api/sales',
                    auth: {
                        username: this.settings.apiUsername,
                        password: this.settings.apiPassword
                    },
                    method: 'get',
                    responseType: 'json'
                })
                    .then((response) => {
                        const sales = response.data
                        this.saleTerm = 'sync.finish'
                        return localforage.setItem('sales', sales)
                            .then(() => this.postSearchRemoteSettings())
                    })
            } catch (error) {
                // eslint-disable-next-line
                console.log(error)
                this.failedSyncronisation()
                this.saleTerm = 'sync.error'
                if (error.response) {
                    this.errorSnackbar = true
                    this.errorMessage = error.response.data.errors[0].message
                }
            }
        },
        async postSearchRemoteSettings () {
            // axios request to get sales
            this.remoteSettingsTerm = 'sync.loading'
            try {
                await axios({
                    url: axios.defaults.baseURL + '/module/orderproposal/api/searchRemoteSettings',
                    auth: {
                        username: this.settings.apiUsername,
                        password: this.settings.apiPassword
                    },
                    method: 'post',
                    responseType: 'json'
                })
                    .then((response) => {
                        const remoteSettings = response.data
                        this.remoteSettingsTerm = 'sync.finish'
                        return localforage.setItem('remoteSettings', remoteSettings)
                    })
            } catch (error) {
                // eslint-disable-next-line
                console.log(error)
                this.failedSyncronisation()
                this.remoteSettingsTerm = 'sync.error'
                if (error.response) {
                    this.errorSnackbar = true
                    this.errorMessage = error.response.data.errors[0].message
                }
            }
        },
        reSyncronisation () {
            this.resync = false
            this.clientTerm = ''
            this.artTerm = ''
            this.priceTerm = ''
            this.packageTerm = ''
            this.saleTerm = ''
            this.remoteSettingsTerm = ''
            this.getClients()
        },
        failedSyncronisation () {
            this.resync = true
        },
        loadFromLocalStorage () {
            // load settings from localStorage
            const settings = localStorage.getItem('settings')
            if (settings) {
                this.settings = {
                    ...this.settings,
                    ...JSON.parse(settings)
                }
            }
        }
    }
}
</script>
<style>
.syncContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.syncContainer header {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
</style>
